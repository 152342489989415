  .container-tour-activity-detail {
    display: flex;
    flex-direction: column; 

    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 50px;
    padding-left: 50px;

  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .badge {
    background-color: #FFC107; 
    color: #000; 
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    margin-bottom: 10px;
    width: 5%;
    display: flex;
    justify-content: center; 
    align-items: center;
  }

  .container-photo {
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: auto;
    grid-gap: 5px;
    width: 100%;
    position: relative;
    border-radius: 15px; 
    overflow: hidden;    
    margin-top: 10px;
  }
  
  .photo {
    grid-column: 1 / 2;
    grid-row: 1 / 3; 
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
   
  .photo-stack {
    display: flex;
    flex-direction: column;
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    grid-gap: 5px;
    position: relative; 
  }
  
  .photo-small {
    width: 100%;
    height: 50%; 
    object-fit: cover;
  }
  
  .gallery-icon {
    position: absolute;
    bottom: 0; 
    left: 0;
    width: 100%;
    text-align: center; 
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px 0; 
    cursor: pointer;
  } 

  .star {
    color: #FFD700; 
    margin-right: 2px;
    font-size: 1em; 

    width: 16px;
  }  

  .bookedCount {
    font-size: 0.9em;
    color: #666;
    margin-left: 5px;
  }

  .carousel-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .carousel-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    max-width: 50%;
    max-height: 90%;
    overflow: auto;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    /* background-color: transparent; */
    border: none;
    color: grey;
    font-size: 20px;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
    z-index: 1001; /* Ensure the button is above the carousel */
  }
  
  /* Styles for the carousel itself */
  .carousel {
    border-radius: 5px;
    overflow: hidden;
  }
  
  /* Styles for carousel slides */
  .carousel .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0; /* Background color of individual slides */
    border-radius: 5px;
  }
  
  /* Styles for carousel slide images */
  .carousel .slide img {
    max-width: 100%;
    max-height: 100%;
  }
  