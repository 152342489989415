.teamContainer {
    text-align: center;
    padding: 40px;
    background-color: #f7f7f7;
    font-family: 'Arial', sans-serif;
  }
  
  .teamContainer h2 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 30px;
  }
  
  .teamGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 0 20px;
    justify-content: center;
  }
  
  .teamMember {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .teamImage {
    width: 100%;
    height: auto;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  .teamMember h3 {
    font-size: 1.5em;
    margin-bottom: 5px;
  }
  
  .teamMember p {
    font-size: 1em;
    color: #666;
  }
  