.container-content-flights {
    display: flex;
    background-color: #f5f5f5;
    width: 80%;
    padding: 10px;
    margin-top: 20px;
    margin-left: auto; 
    margin-right: auto;
}
  
.sidebar {
  width: 20%; 
  border-right: 1px solid #ccc;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  margin-right: 20px;
}
 
.sidebar label{
  color: #333333;
}

.sidebar-divider{
  background-color: #eeeeee;
  height: 1px;
  margin-bottom: 10px;
}

.main-content {
  width: 80%;
  display: flex;
  flex-direction: column;


  
}

.header {
  height: 50px;
  border-bottom: 1px solid #ccc;
  
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  
}

.content {
  flex: 1;
  overflow-y: auto; 

  margin-top: 20px;
}

/* Responsive design */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: auto; /* Adjust height for mobile view */
  }

  .main-content {
    width: 100%;
  }
}
  
.holder-price-range-sort-popular {
  display: flex;
  margin-top: 10px;
}

.selected-holder {
    display: flex;
    flex-wrap: wrap; 
    gap: 5px; 
    padding-top: 15px;

}

.selected-container {
    border-radius: 7px;
    background-color: #f5f5f5;
    padding: 8px;
    align-items: center;
    display: flex;
    justify-content: center;
    width: calc(45.33% - 10px); /* Adjust width for 3 items per row */
    margin-bottom: 5px; /* Optional: space between rows */
    gap: 10px; 
}

.selected-container img{
  cursor:pointer;
}

.selectContainer {
  position: relative;
  width: 100%; 
  font-size: 16px;
}

.selectContainer select {
  background-color: #f5f5f5;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #f5f5f5;
}

.sort-popular-container {
  position: relative;
  font-size: 16px;
  
}

.sort-popular-container select {
  background-color: #f5f5f5;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #f5f5f5;
}

.clear-selection{
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  color: #27a6fb;
  
}

.category-list {
  padding-top: 10px;
}

.category-list img{
    padding-left: 5px;
}

.category-list div{
    padding-bottom: 10px;
}

.category-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.category-item input[type="checkbox"] {
  margin-right: 5px;
  background-color: pink;
}

