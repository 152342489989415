@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

.background1 {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.login-screen {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  width: 100%;
  max-width: 400px;
  text-align: left;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  height: 100%;
  /* backdrop-filter: blur(1px); */
  backdrop-filter: blur(5px);
  padding: 1rem;
}

.login-screen h2 {
  padding-top: 8rem;
  font-size: 2.3rem;
  font-weight: 500;
  margin-bottom: 0;
}

.login-screen small {
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 10rem;
}

.login-screen form {
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
}

.login-screen input[type="text"],
.login-screen input[type="password"] {
  width: 90%;
  margin-bottom: 1rem;
  padding: 1rem;
  color: #fff;
  font-size: 1.2rem;
  background: none;
  border: none;
  border-bottom: 1px solid #fff;
  text-align: center;
  justify-content: center;
  outline: 0;
}

.login-screen input[type="text"]::placeholder,
.login-screen input[type="password"]::placeholder {
  color: #fff;
  font-size: 1.2rem;

}

.login-screen input[type="text"]:hover,
.login-screen input[type="password"]:hover {
  border:1px solid white;
}

.login-button {
  width: 30%;
  padding: 0.5rem;
  margin-top: 6rem;
  margin-bottom: 5rem;
  background-color: #FF5C00;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.login-button:hover {
  background-color: #fff;
  color: #FF5C00;
}

.logo-container {
  display: flex;
  justify-content: left;
  color: #fff;
}

.background {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background-image: url('../assets/image/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding: 0px;
  margin: 0px;
}



.nav {
  margin-top: 50px;
}

.nav-a {
  padding: 0 1rem;
  text-decoration: none;
  color: #fff;
  font-size: 1.5rem;
}

.nav-a:hover {
  border-bottom: 1px solid #FF5C00;
}

.error {
  color: #d32f2f; 
  font-size: 14px;
  margin-bottom: 5px;
}

.error-message {
  text-align: center;
  color: red;
}

@media (max-width: 767px) {
  .login-screen {
    width: 90%;
    padding: 1rem;
  }

  .login-screen input[type="text"],
  .login-screen input[type="password"],
  .login-button {
    width: 100%;
    font-size: 1rem;
    padding: 0.8rem;
  }

  .background {
    background-position: center;
    background-size: cover;
  }
}

@media (max-width: 599px) {
  .login-screen {
    width: 100%;
    padding: 0.8rem;
  }

  .login-screen input[type="text"],
  .login-screen input[type="password"] {
    font-size: 0.9rem;
  }

  .login-button {
    font-size: 0.9rem;
    padding: 0.7rem;
  }
}


@media (min-width: 768px) {
  .login-screen {
    width: 75%;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 0;
    padding-top: 0;
  }

  .login-screen input[type="text"],
  .login-screen input[type="password"] {
    font-size: 1.2rem;
  }

  .login-button {
    font-size: 1.2rem;
  }
}

@media (min-width: 1024px) {
  .login-screen {
    width: 30%;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 0;
    padding-top: 0;

  }

  .login-screen input[type="text"],
  .login-screen input[type="password"] {
    font-size: 1.3rem;
  }

  .login-button {
    font-size: 1.3rem;
  }
}

@media (max-width: 360px) {
  .login-screen {
    width: 95%;
    padding: 0.5rem;
  }

  .login-screen h2 {
    font-size: 1.8rem;
  }

  .login-screen input[type="text"],
  .login-screen input[type="password"] {
    font-size: 0.85rem;
    padding: 0.7rem;
    width: 93%;
  }

  .login-button {
    font-size: 0.85rem;
    padding: 0.6rem;
  }
}

