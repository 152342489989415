.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: white;
}

.filterForm {
    display: flex;
    gap: 10px; 
}

.filterForm select, .filterForm input[type="date"] {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.filterForm button {
    padding: 5px 35px;
    background-color: #ff5b00;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;

}

.filterForm button:hover {
    background-color: #b44407;
}

.selectContainer {
    position: relative;
    width: 100%; 
    font-size: 16px;
}

.selectContainer label {
    position: absolute;
    top: 0;
    left: 5px; 
    background-color: #f5f5f5; 
    padding: 5px;
    margin-left: 5px;
    z-index: 1;
    color: grey;
    font-size: 12px;
}

.selectContainer select {
    height: 60px;
    background-color: #f5f5f5;
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #f5f5f5;
    padding-top: 20px; 
}

.dateContainer {
    position: relative;
    width: 100%;
    font-size: 16px;
}

.dateContainer label {
    position: absolute;
    top: 0; 
    left: 2px; 
    background-color: #f5f5f5; 
    padding: 5px;
    margin-left: 5px;
    z-index: 1;
    color: grey;
    font-size: 12px;
}

.dateContainer input[type="date"] {
    height: 34px;
    background-color: #f5f5f5;
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #f5f5f5;
    padding-top: 20px; 
}

.filterForm select:focus, .dateContainer input[type="date"]:focus {
    border: 1px solid #f5f5f5; 
    outline: none;
}


.container-search {
    display: none;
    align-items: center;
    background-color: #fff;
    border-radius: 25px;
    padding: 5px 10px;
    width: 100%;
    max-width: 400px; 
    margin: 10px auto;
}

.search-input {
    flex-grow: 1;
    border: none;
    background: none;
    padding-left: 10px;
    font-size: 16px;
    outline: none;
}

.search-icon {
    font-size: 20px;
    color: #ff5b00;
}

.animate-placeholder {
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}


.container-traveler-choice {
    padding: 10px;
    /* text-align: center; */
}

.destination-title {
    font-size: 1em;
    margin-bottom: 10px;
    color: #333;
    font-weight: bold;
    padding-top: 10px;
}

.destination-title span {
    font-size: 13px;
}

.traveler-destination-choice {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.location {
    background-color: #f5f5f5;
    border-radius: 15px;
    padding: 8px 16px;
    margin: 5px;

    cursor: pointer;
    transition: background-color 0.3s;
}

.location:hover {
    background-color: #e0e0e0;
}

.container-search-modal {
    align-items: center;
    background-color: #fff;
    border-radius: 25px;
    padding: 5px 10px;
    /* width: 100%; */
    max-width: 400px; 
    margin: 10px auto;
    border: 1px solid #ff5b00
}

.search-input-modal {
    flex-grow: 1;
    border: none;
    background: none;
    padding-left: 10px;
    font-size: 16px;
    outline: none;
}

.search-icon-modal {
    font-size: 20px;
    color: #ff5b00;
}

.container-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

.card-item {
    display: flex;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
    transition: transform 0.3s ease-in-out;
}

.card-item:hover {
    transform: translateY(-5px);
}

.item-photo {
    width: 50%; 
    height: auto;
    object-fit: cover;
}

.item-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    flex-grow: 1;
    text-align: right;
}

.item-title {
    font-size: 15px;
    /* font-weight: bold; */
    color: #212121;
}

.item-title, .item-price {
    margin: 5px 10px;
}


.item-price {
    font-size: 16px;
    color: #ff5b00;
}

@media screen and (max-width: 360px) {
    .container {
        display: none;
    }

    .container-search {
        width: 90%;
        display: flex;
    }

    .search-input {
        font-size: 14px;
    } 
    
    .container-card {
        padding: 0;
    }
}


@media screen and (max-width: 599px) { 
    .modal {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        padding: 20px;
        box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2);
        animation: fadeInUp 0.5s ease-in-out;
        height: 70vh;

        overflow-y: auto; 
        transition: height 0.3s ease; 
    }
    
    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(100%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .location {
        font-size: 0.9em;
    }    
}

@media screen and (min-width: 600px) and (max-width: 767px) { }

@media screen and (min-width: 768px) and (max-width: 1023px) { }

@media screen and (min-width: 1024px) { }