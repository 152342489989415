.container-flights-price-slide {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden; 
    position: relative; 
    justify-content: space-between;



}

.price-card {
    display: flex;
    flex-grow: 1;
    padding: 10px;
    
}

.card-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 100px; 
    margin: 0 10px;
    background-color: #FFF;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 10px;
    transition: background-color 0.3s;
    cursor: pointer;
}

.card-item:hover {
    transform: translateY(-3px); 
    background-color: rgba(255, 87, 34, 0.08);
}

.card-item.active {
    background-color: rgba(255, 87, 34, 0.2); 
    color: #ff5b00
}

.arrow-left, .arrow-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    
    z-index: 1;
  
}


.arrow-left {
    left: 10px;
}

.arrow-right {
    right: 10px;
}

.vertical-divider {
    width: 1px; 
    align-self: stretch; 
    border: 1px solid #eee;
    
    margin: 0 27px;
}