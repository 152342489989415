.flight-ticket {
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    border-radius: 10px;
    margin-top: 20px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid #DDE2E5; /* Mimic ticket border */
    position: relative;
}

.ticket-header {
    display: flex;
    flex-direction: column;
    border-bottom: 2px dashed #DDE2E5; /* Styling to mimic ticket separation */
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.ticket-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.airline-name {
    font-weight: bold;
    color: #333;
    font-size: 1.2em;
}

/* Add styles for .origin-location, .departure-time, etc. */

.ticket-price {
    font-size: 1.4em;
    font-weight: bold;
    color: #FA7468;
    margin-top: 10px;
}

.ticket-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.arrow-down {
    /* Style for arrow down icon */
}

/* Responsive adjustments as needed */
