.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: white;
}

.filterForm {
    display: flex;
    gap: 10px; 
}

.filterForm select, .filterForm input[type="date"] {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.filterForm button {
    padding: 5px 35px;
    background-color: #ff5b00;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;

}

.filterForm button:hover {
    background-color: #b44407;
}

.selectContainer {
    position: relative;
    width: 100%; 
    font-size: 16px;
}

.selectContainer label {
    position: absolute;
    top: 0;
    left: 5px; 
    background-color: #f5f5f5; 
    padding: 5px;
    margin-left: 5px;
    z-index: 1;
    color: grey;
    font-size: 12px;
}

.selectContainer select {
    height: 60px;
    background-color: #f5f5f5;
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #f5f5f5;
    padding-top: 20px; 
}

.dateContainer {
    position: relative;
    width: 100%;
    font-size: 16px;
}

.dateContainer label {
    position: absolute;
    top: 0; 
    left: 2px; 
    background-color: #f5f5f5; 
    padding: 5px;
    margin-left: 5px;
    z-index: 1;
    color: grey;
    font-size: 12px;
}

.dateContainer input[type="date"] {
    height: 34px;
    background-color: #f5f5f5;
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #f5f5f5;
    padding-top: 20px; 
}

.filterForm select:focus, .dateContainer input[type="date"]:focus {
    border: 1px solid #f5f5f5; 
    outline: none;
}

