.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #f5f5f5;
}

.tab-menu {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding-top: 20px;
    background-color: white;
}

.tab-menu a{
    color: #ff5b00;
    text-decoration: none;
    padding-left: 0px !important;
}

.menu-tour-activities, .menu-hotel, .menu-flights {
    padding-left: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex; 
    align-items: center; 
    justify-content: flex-start; 
}

/* Highlight the active tab */
.active {
    background-color: #fef1eb;
    font-weight: bold;
    border-bottom: 2px solid #ff5b00;
    border-radius: 10px 10px 0 0;
}

/* Hover effect */
.menu-tour-activities:hover, .menu-hotel:hover, .menu-flights:hover {
    background-color: #e0e0e0;
    border-radius: 10px 10px 0 0;
}

/* Accessibility focus outline */
.menu-tour-activities:focus, .menu-hotel:focus, .menu-flights:focus {
    outline: none;
    box-shadow: 0 0 0 2px #fef1eb;
}

/* Optional: Animation for tab content change */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Apply animation to tab content */
.tab-content {
    animation: fadeIn 0.5s;
}


.icon-wrapper {
    border-radius: 20px;
    background-color: #ff5b00;
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: column;
    padding: 4px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
}

.menuLogo {
    fill: white;
    margin: 3px; 
    opacity: 0.9;
    transition: opacity 0.3s ease; 
}

.menu-tour-activities a, .menu-hotel a, .menu-flights a {
    margin-left: 10px; 
}

.menu-tour-text{
    display: inline;
}

@media screen and (max-width: 360px) { 
    .menu-tour-text{
        display: none;
    }
    .menu-tour-activities, .menu-hotel, .menu-flights {
        padding: 15px;
    }
}

@media screen and (max-width: 599px) { }

@media screen and (min-width: 600px) and (max-width: 767px) { }

@media screen and (min-width: 768px) and (max-width: 1023px) { }

@media screen and (min-width: 1024px) { }
