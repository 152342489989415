.footer {
    display: flex;
    justify-content: space-evenly;
    background-color: #333; /* Footer background color */
    color: #fff; /* Footer text color */
    padding: 40px 20px;
    flex-wrap: wrap; /* Responsive design */
  }
  
  .footerColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 200px; /* Minimum width for each column */
    margin: 10px;
  }
  
  .footerColumn h4 {
    margin-bottom: 15px;
    font-size: 1.2em; /* Larger font size for headings */
    color: #f8f8f8; /* Slightly off-white color for contrast */
    text-align: center;
  }
  
  .footerColumn ul {
    list-style: none;
    padding: 0;
  }
  
  .footerColumn ul li {
    margin-bottom: 8px; /* Increased spacing for better readability */
    transition: color 0.3s; /* Smooth transition for hover effect */
  }
  
  .footerColumn ul li svg {
    margin-right: 10px; /* Space between icon and text */
  }
  
  .footerColumn a {
    text-decoration: none;
    color: #ccc; /* Lighter color for links */
    display: flex;
    align-items: center;
    transition: color 0.3s; /* Smooth color transition on hover */
    padding:0px;
  }
  
  .footerColumn a:hover {
    color: #f8f8f8; /* Brighter color on hover for better interaction feedback */
  }
  
  /* Responsive design adjustments */
  @media (max-width: 768px) {
    .footer {
      display: block;

    }

    .footerColumn h4 {
      text-align: left; 
    }
  
    .bulletList {
      list-style-type: disc !important; 
      padding-left: 20px !important;
    }
  
    .bulletList li a {
      text-decoration: none; /* Removes the underline from links */
      color: #fff; /* Set the color of the links, change as needed */
     
    }
    
    .bulletList li a:hover {
      text-decoration: underline; /* Adds an underline on hover */
     
    }

    .paymentMethods img {
      /* display: block; */
      display: inline-block;
      margin-left: auto;
      margin-right: auto;
    }
  
    .socialMediaList {
      list-style-type: none;
      padding: 0;
    }
  
    .socialMediaList li {
      display: flex;
      align-items: center;
    }
  
    .socialMediaList li a {
      text-decoration: none;
      color: inherit; /* or any specific color */
    }    
  }
  
  .paymentMethods {
    display: flex;
    justify-content: space-around; /* Evenly distribute the logos */
    align-items: center; /* Align logos vertically */
    flex-wrap: wrap; /* Allow logos to wrap on smaller screens */
    padding: 10px 0; /* Padding above and below the logo row */
    border-top: 1px solid #484848; /* Separator from previous content */
    border-bottom: 1px solid #484848; /* Separator from the next content */
    margin: 20px 0; /* Space outside the payment method section */
    background-color: #606060; /* Lighter background color */
    border-radius: 8px; /* Optional: Adds rounded corners */
  }
  
  .paymentLogo {
    margin: 10px; /* Spacing around each logo */
    height: 30px; /* Consistent height for all logos */
    width: auto; /* Maintain aspect ratio */
    opacity: 0.9; /* Slightly reduce opacity for a softer look */
    transition: opacity 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .paymentLogo:hover {
    opacity: 1; /* Full opacity on hover for interaction feedback */
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 600px) {
    .paymentMethods {
      justify-content: center; /* Center logos on small screens */
    }
  }
  