.container-dashboard {
  color: #fff;
  padding: 20px;
  border-radius: 8px;

}

.weekly-income-label {
  font-size: 1.2em;
  font-weight: bold;
  /* margin-bottom: 20px; */

  color: #FF5C00;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1rem;

}

.container-weekly-income {
  display: flex;
  justify-content: space-around;

  background-color: #282c34; 
  padding: 25px;
  border-radius: 8px;
}

.income-card {
  background: #333;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  min-width: 150px; 

  display: flex;
  flex-direction: column;
  align-items: center;  
  width: 320px;
}

.income-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}


.income-card span {
  display: block;
  margin-bottom: 8px;
}

.amount {
  font-size: 1.5em;
  font-weight: bold;
}

.income-type {
  font-size: 1em;
}

.more-info {
  color: #FF5C00; 
  text-decoration: none;
  font-size: 1em; 
  font-weight: 500; 
  padding: 8px 12px;
  border-radius: 4px; 
  transition: all 0.3s ease; 
  background-color: rgba(255, 255, 255, 0.1); 
  display: inline-block; 
}

.more-info:hover {
  text-decoration: underline;
  background-color: rgba(255, 255, 255, 0.2); 
  color: #ffffff; 
}
.referral-bonus, .unilevel, .weekly-income {
  background: #333; 
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin: 10px;
}

.amount, .income-type {
  color: #fff;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5px;

  display: block;
}

.icon {
  height: 100px; 
  width: 100px;
  margin-right: 10px;
}


.container-total-income {
  display: flex;
  flex-direction: row; /* Align children horizontally */
  gap: 20px; /* Space between sections */
  flex-wrap: wrap; /* Wrap items to next line if space runs out */
  justify-content: center; /* Center items horizontally */
  align-items: flex-start; /* Align items to the start vertically */
  margin-top: 30px;
}


.total-balance, .membership-balance, .travel-fund-balance {
  background: #282c34;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  min-width: 300px; /* Set a minimum width for each section */
}

.total-balance span, .membership-balance span, .travel-fund-balance span {
  display: block;
  margin-bottom: 10px;
  color: #fff;
  font-size: 1.1em;
}

.transaction-button {
  color: #FF5C00;
  text-decoration: none;
  font-size: 0.9em;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 5px;
  display: inline-block;
}

.transaction-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}

@media screen and (max-width: 600px) {
  .weekly-income-label {
      font-size: 14px; /* Adjust font size for smaller screens */
      text-align: center; /* Center align the text */
      padding: 0 10px; /* Add padding on the sides */
  }

  .container-weekly-income {
      flex-direction: column; /* Stack the income cards vertically */
      background-color: transparent;
  }

  .income-card {
      width: 100%; /* Full width for each card */
      margin-bottom: 10px; /* Space between cards */
  }

  .container-total-income > div {
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
  }

  .transaction-button {
      margin-top: 10px; /* Space between buttons */
      width: 90%; /* Adjust button width */
      font-size: 14px; /* Adjust font size */
  }


}

.income-card {
  background: #333;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  min-width: 150px; 

  display: flex;
  flex-direction: column;
  align-items: center;  
  width: 320px;
}


@media screen and (max-width: 360px) { 
  .income-card {
    padding: 0 !important;
    padding-bottom: 10px !important;    
    width: 100% !important;
  }
}

@media screen and (max-width: 599px) { 

}

/* For screens between 600px and 767px */
@media screen and (min-width: 600px) and (max-width: 767px) { 

}

/* For screens between 768px and 1023px */
@media screen and (min-width: 768px) and (max-width: 1023px) { 

}

/* For screens larger than 1024px */
@media screen and (min-width: 1024px) { 

}
