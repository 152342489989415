.top-navigation {
    position: relative;
    z-index: 1000; 
    background-color: #212121;



}

.flexContainer {
    display: flex; 
    align-items: center; 
    justify-content: start; 
    gap: 20px;    

    position: relative; 
    
}

.holder-logo {
    position: absolute;
    left: 50%; 
    transform: translateX(-50%); 
}

  .menuButton {
    color: #fff !important;
  }

.navMenu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #333;
    display: flex;
    flex-direction: column;
    align-items: stretch; 
    z-index: 1001; 

}

.navItem {
    color: white;
    text-decoration: none;
    padding: 10px;
}

.navItem:hover {
    background-color: #444;
}

.logo-span {
    color: #FF5C00;
    font-family: 'Poppins';
}
  
.logo-h2 {
    color: #fff;
    text-align: center;
    font-family: 'Poppins';

}

.logoutButton {
    margin-left: auto !important; 
    color: white !important; 
}

@media screen and (max-width: 360px) { 
    .holder-logo {
        font-size: 13px;
    }
    .navMenu {
        position: fixed; 
        top: 138px;
        overflow-y: auto; 
    }
}

@media screen and (max-width: 599px) { 
    .top-navigation {
        display: block;
    }     

    .navMenu {
        position: fixed; 
        top: 38px;
        overflow-y: auto; 
    }    
}

/* For screens between 600px and 767px */
@media screen and (min-width: 600px) and (max-width: 767px) { 
    .top-navigation {
        display: block;
    }    
}

/* For screens between 768px and 1023px */
@media screen and (min-width: 768px) and (max-width: 1023px) { 
    .top-navigation {
        display: block;
    }    
}

/* For screens larger than 1024px */
@media screen and (min-width: 1024px) { 
    .top-navigation {
        display: none !important;
    }        
}
