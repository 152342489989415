.saveButton {
    background-color: #ff5c00 !important;
    color: #fff !important;
    margin-right: 16px !important;
    transition: background-color 0.3s ease !important;
  }
  
  .saveButton:hover {
    background-color: #ff7d2a !important;
  }
  
  .closeButton {
    background-color: #2a2a2a !important;
    color: #fff !important;
    transition: background-color 0.3s ease !important;
  }
  
  .closeButton:hover {
    background-color: #3a3a3a !important;
  }
  