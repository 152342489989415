.horizontalMenu {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
    
  .horizontalMenu ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    align-items: center;
  }
    
  .horizontalMenu li {
    display: flex; 
    align-items: center;
    padding: 10px;
    margin-right: 20px; 
    cursor: pointer;
  }
  
  .horizontalMenu li:hover {
    background-color: #f2f2f2; 
  }
  
 
  .horizontalMenu li:first-child {
    margin-right: 165px; 
  }
  
  .horizontalMenu li:last-child {
    margin-right: 0px;
  }
  
  .icon {
    margin-right: 8px;
    width:22px;
    vertical-align: middle;
  }
  
  .divider {
    height: 24px;
    border-left: 1px solid #ccc;
    margin-right: 20px;
  }
  
  .menu-text {
    display: inline;
  }

  .menuLogo {
    margin: 10px; /* Spacing around each logo */
    height: 25px; /* Consistent height for all logos */
    width: auto; /* Maintain aspect ratio */
    opacity: 0.9; /* Slightly reduce opacity for a softer look */
    transition: opacity 0.3s ease; /* Smooth transition for hover effect */
  }

  @media screen and (max-width: 600px) {
    .menu-text {
      display: none; 
    }
  
    .icon {
      display: inline; /* Show icons on small screens */
    }

    .horizontalMenu ul {
        padding-left: 5px;
        padding-right: 5px;
    }

    .horizontalMenu li, .horizontalMenu li:first-child {
        margin: 0px;
    }
    
  }

  @media (max-width: 360px) {
    .horizontalMenu li {
        padding:5px;
    }
    
  }