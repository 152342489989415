.buttonSearch {
    background-color: #2a2a2a !important;
    color: #fff !important;
    margin-right: 8px !important;
    transition: background-color 0.3s ease !important;
  }
  
  .buttonSearch:hover {
    background-color: #3a3a3a !important;
  }
  
  .buttonNew {
    background-color: #ff5c00 !important;
    color: #fff !important;
    margin-right: 8px !important;
    transition: background-color 0.3s ease !important;
  }
  
  .buttonNew:hover {
    background-color: #ff7d2a !important;
  }
  
  .buttonClear {
    background-color: #777 !important;
    color: #fff !important;
    margin-right: 8px !important;
    transition: background-color 0.3s ease !important;
  }
  
  .buttonClear:hover {
    background-color: #999 !important;
  }
  