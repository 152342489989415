.header {
    display: flex;
    justify-content:  space-between;
    align-items: center;
    padding: 10px 20px;
    border: 1px solid #eee;
    height: 2em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
}

.logo {
    max-width: 150px; 
    height: auto;
    cursor: pointer;
}

.login{
    align-items: center;
    background-color: var(--color-orangered-100);
    border-radius: var(--br-31xl);
    color: var(--color-white);
    display: flex;
    flex-direction: row;
    padding: var(--padding-4xs) var(--padding-xs);

}

.loginSection {
    display: flex;
    align-items: center;
    gap: 5px; 
}

.questionsLabel, .signupLink, .loginLink {
    color: #333;
    font-size: 15px; 
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 5px; 
    transition: all 0.3s ease;
}

.questionsLabel, .signupLink {
    color: #333; 
    font-size: 14px;
    text-decoration: none; 
  }

.loginLink {
    display: inline-block;
    padding: 8px 15px;
    background-color: #ff5b00;
    color: white;
    text-decoration: none;
    border-radius: 40px;
    transition: background-color 0.3s, color 0.3s;

    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;    
}
  
.loginLink:hover, .loginLink:focus {
    background-color: #b44407;
    color: #fff;
    text-decoration: none;
}

.signupLink:hover, .loginLink:hover {
    background-color: #b44407; 
    color: #fff;
    text-decoration: none;
  }

.select__control {
    border: 1px solid #ced4da; 
    border-radius: 5px;
}

.select__menu {
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.select__option {
    display: flex;
    align-items: center;
    gap: 10px; 
}  

.hamburger {
    display: none;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    font-size: 24px; 
}
  
.mobileMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

@media (max-width: 768px) {
    .hamburger {
        display: block;
    }
    .loginSection {
        display: none;
    }
    
    .mobileMenu {
        display: none; 
    }  
    
    .verticalMenu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%; 
        background-color: white; 
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 1000; 
        display: flex;
        flex-direction: column;
        padding: 10px; 
        box-sizing: border-box;
      }
      
      .verticalMenu a, .verticalMenu span {
        text-decoration: none;
        color: black; 
        display: block;
      }
      
      .verticalMenu a:hover {
        background-color: #f0f0f0; 
      }    
}
  