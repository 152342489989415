/* Button.module.css */
.customButton {
    background-color: #ff5c00;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .customButton:hover {
    background-color: #ff7d2a;  /* A lighter shade of the original orange for hover effect */
  }
  