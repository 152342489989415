.container {
    display: flex; 
    flex-direction: column; 
    justify-content: flex-start; 
    height: 100vh;
}


.sliderSection {
  position: relative;
  background-image: url('../../assets/image/banner@3x.png'); 
  background-size: cover;
  background-position: center;
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
  
}


.sliderContent {
  text-align: center;
  position: relative;
  width: 100%;
}

.mainHeading {
  font-size: 3.5em; /* Adjusted font size */
  color: #ff5b00; /* Bright, attention-grabbing color */
  font-weight: bold; /* Bold for impact */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow for depth */
  margin-bottom: 15px; /* Spacing between heading and subheading */  
}

.subHeading {
  font-size: 1.8em; /* Larger than before for emphasis */
  color: #fff; /* White for contrast */
  font-weight: 300; /* Lighter weight for balance */
  padding: 0 30px; /* Padding for better text wrapping */
  line-height: 1.4; /* Line height for readability */
  margin: 0 auto 20px; /* Spacing around the subheading */
  max-width: 70%; /* Adjusted max width */  
}

.bookNowButton {
  font-size: 1.8em;  /* Large font size for visibility */
  color: #fff; /* Text color */
  background-color: #ff5b00; /* Button background color */
  border: none; /* No border for a modern look */
  padding: 10px 20px; /* Padding inside the button */
  margin-top: 20px; /* Space between the subheading and the button */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 5px; /* Slightly rounded corners for a modern look */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
}

.bookNowButton:hover {
  background-color: #e65000; /* Darker shade on hover for interaction feedback */
  transform: translateY(-2px); /* Slight raise effect on hover */
}

@media (max-width: 768px) {
  .sliderSection {
    height: auto; /* Adjust the height for mobile */
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .sliderContent {
    padding: 0 10px; /* Adjust padding for smaller screens */
  }

  .mainHeading {
    font-size: 2em; /* Further reduce font size for small screens */
    margin-bottom: 10px; /* Adjust spacing */
  }

  .subHeading {
    font-size: 1.2em; /* Adjust subheading font size */
    padding: 0 10px; /* Adjust padding */
    max-width: 90%; /* Increase max-width for better readability */
  }

  .bookNowButton {
    font-size: 1.5em; /* Adjust button font size */
    padding: 8px 16px; /* Adjust button padding */  
    margin-top: 15px; /* Adjust spacing */
  }

  .travelExperienceTitle {
    font-size: 1.5em !important; 
    max-width: 90% !important; 
    margin-bottom: 20px center !important; 
    text-align: center !important; 
  }

  .arrowLeftTravel,
  .arrowRightTravel {
    top: 40%; 
    transform: translateY(-40%);
  }

  /* Consider hiding or resizing arrows if they are too intrusive on mobile */
  .arrowLeftTravel, 
  .arrowRightTravel {
    display: none; 
  }


  .horizontalMenu ul {
    flex-direction: column; 
  }
  .horizontalMenu li {
    padding: 5px; 
  }  
}

.travelExperienceSection {
  margin-top: 50px; 
  text-align: center; 
  
}

.travelExperienceTitle {
  font-size: 2em; 
  margin-bottom: 30px; 

  display: block;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%; 
}

.packageTourSlider {
  position: relative; 
  max-width: 80%;
  margin: auto; 
}

.packageTourSlides {
  display: flex; 
  overflow-x: scroll; 
  scroll-snap-type: x mandatory; 
}

.packageTourImage {
  width: 200px;
  margin: 0 5px; 
  transition: transform 0.3s ease; 
}

.arrowLeftTravel,
.arrowRightTravel {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: #ffffffa0;
  border-radius: 50%;
}

.arrowLeftTravel {
  left: 10px;
}

.arrowRightTravel {
  right: 10px;
}


.faqSection {
  padding: 20px;
  max-width: 80%;
  margin: 20px auto;
  background-color: #f7f7f7; 
  border-radius: 10px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.faqTitle {
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #333;
  font-weight: 500; 
}

.faqItem {
  margin-bottom: 15px;
  background-color: #fff; 
  border-radius: 8px; 
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.faqQuestion {
  width: 100%;
  text-align: left;
  background-color: transparent; 
  color: #000;
  padding: 12px 20px;
  border: 1px solid #d8d5d5;
  border-radius: 8px 8px 0 0; 
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.faqQuestion:hover {
  background-color: #d8d5d5; 
}

.faqAnswer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: 0 20px; 
  border-radius: 0 0 8px 8px; 
}

.faqAnswer.active {
  max-height: 1000px; 
  padding: 20px; 
}
