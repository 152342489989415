.container-tour-activity {
    display: flex;
    flex-direction: column;
    margin: 20px;
    color: #212121;
  }
  
  .container-form-filter {
    /* display: flex; */
    margin-bottom: 20px;
    align-items: center;
  }
  
  .search-input {
    margin-right: 10px;
    padding: 10px 15px;
    border: 2px solid #ced4da; /* Light grey border */
    border-radius: 0.25rem;
    flex-grow: 1;
    font-size: 1rem; /* Larger font size */
  }
  
  .btn {
    padding: 10px 15px;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.2s ease-in-out; /* Smooth transition for hover effects */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem; /* Space between icon and text */
  }

  .btn-clear {
    background-color: #6c757d; /* Bootstrap secondary grey */
  }

  .btn-search, .btn-new, .btn-clear {
    margin-left: 10px;
    
  }

  .btn-search, .btn-new, .btn-edit {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;
  }
  
  .btn-edit, .btn-clear {
    background-color: #007bff; /* Bootstrap primary blue */
    color: white;
  }

  .btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

  .btn-search {
    background-color: #FF5C00;
    color: white;
    padding: 12px;
  }
  
  .btn-new {
    background-color: #212121;
    color: white;
    padding: 12px;
  }
  
  .btn-edit {
    background-color: #FF5C00;
    color: white;
  }
  
  .btn-clear {
    margin-left: 10px; 
    padding: 12px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #e0e0e0; 
    color: #212121; 
    transition: background-color 0.3s; 
  }
  
  .btn-clear:hover {
    background-color: #d0d0d0; 
  }
  

  .container-data {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    border-radius: 0.25rem;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #dee2e6; /* Light grey border */
    padding: 12px 15px; /* Larger padding for table cells */
    text-align: left;
  }
  
  th {
    background-color: #212121; 
    color: #fff; 
    border: none;
  }
  
  tr:nth-child(even) {
    background-color: #f8f9fa; /* Zebra striping for rows */
  }

 
  @media screen and (max-width: 360px) { 
    .container-form-filter {
      flex-direction: column; /* Stack elements vertically */
      align-items: stretch; /* Stretch items to fill the width */
    }
  
    .search-input,
    .btn-search, 
    .btn-new, 
    .btn-clear {
      /* width: 100%;  */
      margin-top: 10px; 
      /* margin-left:0; */
    }
    .btn-clear {
      padding: 14px !important;
    }

    .search-input {
      margin-top: 0; /* No margin for the first element */
      width: 90%;
    }
  
    .btn-search span, .btn-new span, .btn-clear span {
      /* display: none; */
    }
  
    table tr {
      width: 87%;
    }
  
    table td {
      font-size: 0.7em;
      padding: 8px 4px;
    }
  
    .btn-edit, .btn-clear {
      padding: 4px;
      font-size: 0.6em;
    }  
  }

  @media screen and (max-width: 599px) { 
    
    .container-tour-activity {
      /* margin: 10px; */
    }
  
    .search-input{
      margin: 0;
    }
    .search-input, .btn {
      font-size: 0.9rem; /* Smaller font size for inputs and buttons */
    }
  
    .btn {
      padding: 8px 10px; /* Adjust padding for buttons */
    }
  
    .income-card {
      /* Adjustments for income card layout */
      flex-direction: row;
      gap: 5px;
    }
  
    .icon {
      width: 30px; /* Smaller icons */
    } 
    
    table {
      border: 0;
    }
  
    table thead {
      display: none;
    }
  
    table tr {
      margin-bottom: 10px;
      /* display: block; */
      border-bottom: 2px solid #ddd;
    }
  
    table td {
      display: block;
      text-align: right;
      font-size: 0.8em;
      border-bottom: 1px dotted #ccc;
    }
  
    table td:last-child {
      border-bottom: 0;
    }
  
    table td:before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
      margin-right: 10px; /* Add space between label and data */
    }
  
    /* Adjusting button sizes for smaller screens */
    .btn-edit, .btn-clear {
      padding: 5px;
      font-size: 0.7em;
    }    
  }
  
  /* For screens between 600px and 767px */
  @media screen and (min-width: 600px) and (max-width: 767px) { 
    .container-form-filter {
      flex-direction: column;
      align-items: stretch;
      gap: 10px; /* Add some space between elements */
    }
  
    .btn {
      width: 100%;
      margin-top: 10px;
    }
  
    .btn-clear {
      order: -1; /* Move Clear button to the top */
    }
  
    table {
      border: 0;
    }
  
    table thead {
      display: none;
    }
  
    table tr {
      margin-bottom: 10px;
      display: block;
      border-bottom: 2px solid #ddd;
    }
  
    table td {
      display: block;
      text-align: right;
      font-size: 0.8em;
      border-bottom: 1px dotted #ccc;
    }
  
    table td:last-child {
      border-bottom: 0;
    }
  
    table td:before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    .container-data {
      margin-top: 10px;
    } 
  }
  
  /* For screens between 768px and 1023px */
  @media screen and (min-width: 768px) and (max-width: 1023px) { 
  
  }
  
  /* For screens larger than 1024px */
  @media screen and (min-width: 1024px) { 
  
  }
  