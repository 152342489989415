.aboutUsContainer {
    color: #1c1c1c; 
    background-color: #ffffff; 
    font-family: 'Open Sans', sans-serif; /* A more modern font */
    line-height: 1.6;
  }
  
  .aboutUsBanner {
    background-color: #fff; /* More neutral background */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
  }
  
  .aboutUsContent {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .aboutUsHeading {
    font-size: 2.5em;
    color: #50d5fe; /* Using your color as an accent */
    margin-bottom: 20px;
  }
  
  .aboutUsSubHeading {
    font-size: 1.2em;
    color: #1c1c1c;
    margin-bottom: 30px;
  }
  
  .aboutUsButton {
    background-color: #ef8901; 
    color: #ffffff;
    border: none;
    padding: 15px 30px;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .aboutUsButton:hover {
    background-color: #feb803; 
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Adds shadow for depth */
  }
  
  .missionVisionSection {
    padding: 60px 20px;
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .mission, .vision {
    max-width: 800px; 
    margin-bottom: 40px; 
  }
  
  .sectionTitle {
    font-size: 2em;
    color: #50d5fe; /* Accent color for titles */
    margin-bottom: 25px;
  }
  
  .sectionContent {
    font-size: 1.1em;
    max-width: 70%;
    margin: 0 auto; 
    line-height: 1.8;
    color: #1c1c1c; 
    text-align: justify; 
  }
  
  @media (max-width: 768px) {
    .aboutUsBanner, .missionVisionSection {
      padding: 30px 10px;
    }
  
    .aboutUsHeading, .sectionTitle {
      font-size: 1.8em;
    }
  
    .aboutUsSubHeading, .sectionContent {
      font-size: 1em;
      max-width: 90%;
    }
  
    .aboutUsButton {
      padding: 12px 20px;
    }
  }

  
/* .aboutUsContainer {
    color: #1c1c1c; 
    background-color: #ffffff; 
    font-family: 'Arial', sans-serif; 
    line-height: 1.6;
  }
  
  .aboutUsBanner {
    background-color: #50d5fe; 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
  }
  
  .aboutUsContent {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .aboutUsHeading {
    font-size: 2.5em;
    color: #1c1c1c;
    margin-bottom: 15px;
  }
  
  .aboutUsSubHeading {
    font-size: 1.2em;
    color: #1c1c1c;
    margin-bottom: 25px;
  }
  
  .aboutUsButton {
    background-color: #ef8901; 
    color: #ffffff;
    border: none;
    padding: 12px 25px;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .aboutUsButton:hover {
    background-color: #feb803; 
  }
  
  .missionVisionSection {
    padding: 50px 20px;
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; 
  }
  
  .mission, .vision {
    max-width: 800px; 
    margin-bottom: 30px; 
  }

  .sectionTitle {
    font-size: 2em;
    color: #1c1c1c; 
    margin-bottom: 20px;
  }
  
  .sectionContent {
    font-size: 1.1em;
    max-width: 70%;
    margin: 0 auto 30px; 
    line-height: 1.6;
    color: #1c1c1c; 
    text-align: justify; 
  }
  
  @media (max-width: 768px) {
    .aboutUsBanner, .missionVisionSection {
      padding: 30px 10px;
    }
  
    .aboutUsHeading, .sectionTitle {
      font-size: 1.8em;
    }
  
    .aboutUsSubHeading, .sectionContent {
      font-size: 1em;
      max-width: 90%;
    }
  
    .aboutUsButton {
      padding: 10px 20px;
    }
  }
   */