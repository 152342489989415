
.tableHeader {
    background-color: #ff5c00;
}

.buttonEnabled {
    background-color: None;
  }
  
.buttonDisabled {
    background-color: gray !important;
}