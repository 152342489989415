
.holder-content-hotel-data {
    text-align: center;
    margin-left: auto; 
    margin-right: auto;
}

.hotelList {
    display: flex;
    flex-wrap: wrap; 
}

.hotelCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  width: calc(25% - 10px);
  margin-bottom: 20px;
  padding-left: 8px;
  min-height: 300px;

  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.hotelCard:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
  
.hotelImage {
  width: 100%;
  height: 170px !important; 
  border-radius: 5px;

  transition: transform 0.3s ease;
}
  
.hotelImage:hover {
  transform: scale(1.03);
}
  .hotelInfo {
    text-align: left;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.8); 
    border-radius: 5px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    margin-top: -10px; 
    position: relative;
    
    flex-grow: 1;
  }
  
  .hotelInfo p {
    margin: 5px 0; 
    color: #333; 
    font-size: 1em;    
  }
  


  .hotelInfo p:first-child { 
    /* font-weight: bold; 
    color: #757575; */

    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    color: #757575;    
  }
  
  .hotelInfo p:nth-child(2) {
    font-weight: bold; 
    color: #5a5858;
    /* font-size: 1.1em; */

    
    font-size: 1.2em;
        
  }
  
  
  .hotelInfo p:last-child { 
    color: #000000; 
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .badge {
    display: inline-block;
    background-color: #f7e3da; 
    color: #fa746a; 
    padding: 3px 8px; 
    border-radius: 12px; 
    font-size: 0.8em; 
    margin-left: 10px; 
    text-transform: uppercase; 
    font-weight: bold;
  }
  
  .star {
    color: #FFD700; 
    margin-right: 2px;
    font-size: 1em; 

    width: 16px;
  }

  .bookedCount {
    font-size: 0.9em;
    color: #666;
    margin-left: 5px;
  }

  @media (max-width: 768px) {
    .holder-content-hotel-data {
      max-width: 100%; /* Full width on smaller screens */
    }
  
    .hotelCard {
      width: calc(50% - 10px); /* Increase width for better readability on smaller screens */
    }
  
    .popularDestinationTitle {
      font-size: 1.5em; 
      text-align: center;
    }
  
    .hotelInfo p {
      font-size: 1.0em; 
    }
  
    @media (max-width: 480px) {
      .hotelCard {
        width: 100%;
      }
    }
  }

  .hotelFacilities {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.facility {
    background-color: #f0f0f0;
    padding: 10px;
    margin-right: 5px;
    border-radius: 5px;
    font-size: 0.8em;


    display: inline-flex;
    align-items: center;
}  

.hotelPrice {
  text-align: left; 
  margin: 5px 0; 
  padding: 5px 10px;
  font-size: 1.1em; 
  font-weight: bold;
  color: #E84A5F; 
  background-color: #f8f8f8; 
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.hotelPrice:hover {
  transform: scale(1.05);
  background-color: #f0f0f0; 
}


