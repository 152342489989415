/* For screens smaller than 600px */
@media screen and (max-width: 599px) { 
    .sidebar-burger-icon {
      display: none !important;
    }  
}

/* For screens between 600px and 767px */
@media screen and (min-width: 600px) and (max-width: 767px) { 
    .sidebar-burger-icon {
        display: none !important;
    }      
}

/* For screens between 768px and 1023px */
@media screen and (min-width: 768px) and (max-width: 1023px) { 
    .sidebar-burger-icon {
        display: none !important;
    } 


}

/* For screens larger than 1024px */
@media screen and (min-width: 1024px) { 
    .sidebar-burger-icon {
        display: block;
    } 

}
