.contactUsContainer {
    max-width: 600px;
    margin: 25px auto;
    padding: 40px;
    background-color: #ffffff; /* White background for a clean look */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    border-radius: 8px; /* Rounded corners */
    text-align: center;
    font-family: 'Arial', sans-serif; /* Professional font */
  }
  
  .contactForm {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contactForm label {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 1em; /* Adequate font size for readability */
    color: #333333; /* Darker color for better contrast */
  }
  
  .contactForm input,
  .contactForm textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    font-size: 1em; /* Comfortable reading size */
  }
  
  .contactForm textarea {
    height: 150px;
    resize: vertical;
  }
  
  .submitBtn {
    background-color: #4CAF50; /* Green for a vibrant, positive action color */
    color: white;
    padding: 15px 25px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  .submitBtn:hover {
    background-color: #45a049; /* Slightly darker shade for interaction feedback */
  }
  