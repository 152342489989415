.bannerSection {
    position: relative;
    background-image: url('../../assets/image/banner1@3x.png'); 
    background-size: cover;
    background-position: center;
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px;
    
  }

  .mainHeading {
    font-size: 4.0em;
    color: #fff;
    display: block;
  }