
.tourByInterestSection {
    text-align: left;
    padding: 20px;
    margin-top: 20px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .tourByInterestTitle {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .tourList {
    display: flex;
    justify-content: space-around;
  }
  
  .tourCard {
    width: calc(33% - 10px); /* Adjust based on the number of items per row */
    margin-bottom: 20px;
    position: relative;
  }
  
  .tourImage {
    width: 90%;
    height: auto;
    border-radius: 10px;
  }
  
  .tourTitle {
    position: absolute;
    bottom: 20px; /* Adjust based on where you want the title */
    left: 20px;
    color: #fff; /* Title color */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for readability */
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 1.0em;
    
  }

  @media (max-width: 768px) {
    .tourByInterestTitle {
      text-align: center;
    }

    .tourCard {
      width: calc(50% - 10px); 
    }
  
    .tourTitle {
      font-size: 0.8em; 
      left: 15px;
    }

    .tourList {
      flex-wrap: wrap;
    }
        
  }

  @media (max-width: 480px) {
    .tourByInterestSection {
      max-width: 100%;
    }
  

    .tourTitle {
      font-size: 0.8em; 
      left: 10px;
    }

    .tourList {
      flex-wrap: wrap;
    }    
  }

  @media (max-width: 360px) {
    .tourTitle {
      font-size: 0.7em; 
      left: 10px;
    }
  }