
.popularDestinationSection {
    text-align: center;
    padding: 20px;
    margin-top: 20px;
    max-width: 80%; 
    margin-left: auto; 
    margin-right: auto;
  }
  
  .popularDestinationTitle {
    font-size: 2em;
    margin-bottom: 20px;
    text-align: left; 
  }
  .destinationList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; 
  }
  
  .destinationCard {
    width: calc(25% - 10px); 
    margin-bottom: 20px;
  }
  
  .destinationImage {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .destinationInfo {
    text-align: left;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.8); 
    border-radius: 5px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    margin-top: -10px; 
    position: relative; 
  }
  
  .destinationInfo p {
    margin: 5px 0; 
    color: #333; 
    font-size: 0.9em; 
  }
  
  .destinationInfo p:first-child { 
    font-weight: bold; 
    color: #757575
  }
  
  .destinationInfo p:nth-child(2) {
    font-weight: bold; 
    color: #5a5858;
    font-size: 16px;
  }
  
  
  .destinationInfo p:last-child { 
    color: #000000; 
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .badge {
    display: inline-block;
    background-color: #f7e3da; 
    color: #fa746a; 
    padding: 3px 8px; 
    border-radius: 12px; 
    font-size: 0.8em; 
    margin-left: 10px; 
    text-transform: uppercase; 
    font-weight: bold;
  }
  
  .star {
    color: #FFD700; 
    margin-right: 2px;
    font-size: 1em; 
  }
  
  .seeMoreButton {
    font-size: 1em;
    padding: 12px 24px;
    margin-top: 20px;
    color: #007bff; 
    background-color: transparent; 
    border: 2px solid #007bff; 
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
    box-shadow: none;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .seeMoreButton:hover, .seeMoreButton:focus {
    color: #fff; 
    background-color: #0056b3; 
    transform: translateY(-2px);
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .seeMoreButton:focus-visible {
    box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
  }
  
  @media (max-width: 768px) {
    .popularDestinationSection {
      max-width: 100%; /* Full width on smaller screens */
    }
  
    .destinationCard {
      width: calc(50% - 10px); /* Increase width for better readability on smaller screens */
    }
  
    .popularDestinationTitle {
      font-size: 1.5em; 
      text-align: center;
    }
  
    .destinationInfo p {
      font-size: 1.0em; 
    }

    .seeMoreButton {
      font-size: 1em; /* Adjust button size */
      padding: 10px 20px; /* Adjust button padding */
    }
  
    /* If the destination cards are still too large, consider this */
    @media (max-width: 480px) {
      .destinationCard {
        width: 100%; /* Full width for very small screens */
      }
    }
  }